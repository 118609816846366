import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"

import "../styles/templates/markdown-page.scss"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const pageName = frontmatter.title
    .split(" ")
    .map(a => a.toLowerCase())
    .join("-")
  return (
    <Layout
      stripeClass="is-solid-grey"
      pageName={`text-page ${pageName}`}
      hasLessHeightStripe
      hasLightBackground
      TwistedShapesResponsive={null}
      TwistedShapesLeft={null}
      TwistedShapesRight={null}
    >
      <section
        style={{ marginBottom: 0 }}
        className={`section is-hero ${
          frontmatter.subtitle
            ? "has-margin-bottom-5 has-margin-bottom-0-mobile"
            : "has-margin-bottom-7 "
        }`}
      >
        <div className="container">
          <h1 className="title  is-spaced is-2 has-text-centered">
            {frontmatter.title}
          </h1>
          {frontmatter.subtitle &&
            (frontmatter.subtitleLinkToPage ? (
              <Link to={`/${frontmatter.subtitleLinkToPage}`}>
                <h2
                  style={{ maxWidth: 700, margin: "auto" }}
                  className="subtitle  is-4 has-margin-bottom-6 has-text-centered"
                >
                  {frontmatter.subtitle}
                </h2>
              </Link>
            ) : (
              <h2
                style={{ maxWidth: 700, margin: "auto" }}
                className="subtitle  is-4 has-margin-bottom-6 has-text-centered"
              >
                {frontmatter.subtitle}
              </h2>
            ))}
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-two-thirds">
              <div
                className="text-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($originalPath: String!, $locale: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $originalPath }, locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        path
        title
        subtitle
        subtitleLinkToPage
      }
    }
  }
`
